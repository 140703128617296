<template>
  <div class="batchBox">
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
      </div>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label">商品名称</template>
          {{detail.info.goods_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否上架</template>
          {{detail.info.status == 1?'是':'否'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">积分价格</template>
          {{detail.info.price}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建时间</template>
          {{detail.info.created_at}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品备注</template>
          {{detail.info.message}}
        </el-descriptions-item>
      </el-descriptions>
      <div class="title">
        <i class="el-icon-collection-tag" />BANNER
      </div>
      <div class="banner">
        <el-row >
           <el-col :span="4" class="banner-item" v-for="item in detail.banner">
             <el-image :src="item.url" :fit="fit"></el-image>
           </el-col>
         </el-row>
      </div>
      <div class="title">
        <i class="el-icon-collection-tag" />详情图
      </div>
      <div class="banner">
        <el-row >
           <el-col :span="4" class="banner-item" v-for="item in detail.image">
             <el-image :src="item.url" :fit="fit"></el-image>
           </el-col>
         </el-row>
      </div>
    </div>
    <div class="title">
      <i class="el-icon-collection-tag" />内含物品
    </div>
    <el-row>
            <el-col :span="24">
              <el-table
                :data="detail.object_list"
                size="small"
                border
                :cell-style="$style.cellStyle"
                :header-cell-style="$style.rowClass"
                @selection-change="handleSelectionChange"
              >
                <!-- <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" /> -->
              <ElTableColumn label="物品名称" prop="name" />
              <ElTableColumn label="数量"  prop="number" />
              <ElTableColumn label="物品种类"  prop="type_name" />
              <ElTableColumn label="天数/次数">
                <template slot-scope="{ row }">
                  <div v-if="row.service_number != 0">{{row.service_number}}次</div>
                  <div v-if="row.service_days != 0">{{row.service_days}}天</div>
                  <div v-if="row.service_days == 0&&row.service_number==0">--</div>
                </template>
              </ElTableColumn>
              </el-table>
            </el-col>
          </el-row>
  </div>
</template>

<script>
import { getPointGoodsDetail } from '@/api/goods';
class Detail {
  info=[];
  banner=[];
  image=[];
  object_list=[];
}
export default {
  data () {
    return {
      detail:new Detail(),
      fit:"contain"
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail(){
       getPointGoodsDetail({id:this.$route.query.id} ).then(res => {
         console.log(res);
        this.detail = res.data;
        console.log(this.detail);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: #f9fafc;
}
.banner{
  width: 90%;
  margin: auto;
}
.banner-item{
  margin: 20px;
  width: 10%;
  // height: 180px;
  padding: 5px;
  border: #666 solid 1px;
  // background-color: red;
}
</style>
